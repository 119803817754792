import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { Avatar, Box, Button, Dialog, DialogContent, IconButton, Stack, TextField, Typography } from '@mui/material'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import dayjs from 'dayjs'
import React, { SetStateAction, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { USER_PERMISSIONS } from 'src/common/constants/adminPermissions'
import { GET_PRESALES_CODES, PRESALES_QUERY_KEY } from 'src/common/constants/queryKeys'
import Loader from 'src/components/general/loader'
import useAuth from 'src/hooks/useAuth'
import { adminApi } from 'src/services/adminApi'
import { PresalesAPI } from 'src/services/presalesAPI'
import { Datum } from 'src/types/local/upSalesResp'
import { applyTimeZone } from 'src/utils/dataFormatters'
import { FrontEndError } from 'src/utils/error'

export function PresaleInformationModal({
    item,
    setOpen,
    timezone,
}: {
    item?: Datum
    setOpen?: React.Dispatch<SetStateAction<boolean>>
    timezone
}) {
    const {
        user: { permissions },
    } = useAuth()

    const queryClient = useQueryClient()

    const [isLoading, setIsLoading] = useState(false)

    const [isAddingCodeModalOpen, setIsAddingCodeModalOpen] = useState(false)
    const [newCodeName, setNewCodeName] = useState('')
    const [newCodeValue, setNewCodeValue] = useState('')

    const formatCustomDate = (dateString: string, timezone: string) => {
        if (!dateString || !timezone) {
            return ''
        }

        try {
            const date = new Date(applyTimeZone(dateString, timezone))
            const timezoneAbbreviation =
                new Intl.DateTimeFormat('en-US', { timeZone: timezone, timeZoneName: 'short' })
                    ?.formatToParts(date)
                    ?.find((part) => part.type === 'timeZoneName')?.value || ''
            return dayjs(date).format(`ddd MM/DD/YY h:mm a [${timezoneAbbreviation}]`)
        } catch (err) {
            FrontEndError(err)
            return ''
        }
    }

    // const itemTimezone = useMemo(() => {
    //     switch (timezone) {
    //         case 'local':
    //             return item?.timezone
    //         case 'user':
    //             return Intl.DateTimeFormat().resolvedOptions().timeZone
    //         default:
    //             return Intl.DateTimeFormat().resolvedOptions().timeZone
    //     }
    // }, [timezone, item])

    const { data: codesData } = useQuery({
        queryKey: [GET_PRESALES_CODES],
        queryFn: PresalesAPI.getPresaleCodes,
    })

    const { presalesWithDateArr, otherCodes } = useMemo(() => {
        if (!codesData || !item) {
            return { presalesWithDateArr: [], otherCodes: [] }
        }

        let presalesWithDateArr = []
        let otherCodes = []
        let globalCodes = [...codesData]
        let artistCodes =
            item.artistsStat.sort((a, b) => b.spotifyAPI?.popularity - a.spotifyAPI?.popularity)[0]?.presale_codes || []

        let presaleCodes = item.presale_codes
            ? item.presale_codes.reduce((acc, { presaleName, presaleCode }) => {
                  const existing = acc.find((entry) => entry.regex === presaleName)
                  existing
                      ? existing.codes.push(presaleCode)
                      : acc.push({
                            regex: presaleName,
                            codes: [
                                {
                                    name: presaleName,
                                    code: presaleCode,
                                },
                            ],
                        })

                  return acc
              }, [])
            : []

        presaleCodes.push({
            regex: 'artist',
            codes: artistCodes.map((v) => ({
                name: 'ARTIST',
                code: v,
            })),
        })

        presalesWithDateArr = (item.presales || [])
            .sort((a, b) => new Date(a.startDateTime).getTime() - new Date(b.startDateTime).getTime())
            .map(({ name, startDateTime }) => {
                const code = presaleCodes.findIndex(({ regex }) => new RegExp(regex, 'i').test(name))
                const globalCode = globalCodes.findIndex(({ regex }) => new RegExp(regex, 'i').test(name))

                if (code > -1) {
                    const codes = presaleCodes[code].codes
                    presaleCodes = presaleCodes.slice(0, code).concat(presaleCodes.slice(code + 1))

                    return {
                        name,
                        startDateTime,
                        codes,
                    }
                } else if (globalCode > -1) {
                    const codes = globalCodes[globalCode].codes
                    globalCodes = globalCodes.slice(0, globalCode).concat(globalCodes.slice(globalCode + 1))

                    return {
                        name,
                        startDateTime,
                        codes,
                    }
                } else {
                    return {
                        name,
                        startDateTime,
                        codes: [],
                    }
                }
            })

        otherCodes = presaleCodes

        return { presalesWithDateArr, otherCodes }
    }, [item, codesData])

    const { mutate: addPresaleCodeMutation } = useMutation({
        mutationFn: () => {
            return adminApi.addPresaleCode(item?._id, newCodeName, newCodeValue)
        },
        onMutate: () => {
            setIsLoading(true)
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [PRESALES_QUERY_KEY] })
            setIsLoading(false)
            setIsAddingCodeModalOpen(false)
        },
    })

    return (
        <>
            <Dialog open={isAddingCodeModalOpen} onClose={() => setIsAddingCodeModalOpen(false)}>
                <DialogContent>
                    <TextField
                        label="New Code Name"
                        value={newCodeName}
                        onChange={(e) => setNewCodeName(e.target.value)}
                        fullWidth
                        sx={{
                            '& .MuiInputLabel-root': {
                                color: 'black',
                            },
                            my: 2,
                        }}
                    />
                    <TextField
                        label="New Code Value"
                        value={newCodeValue}
                        onChange={(e) => setNewCodeValue(e.target.value)}
                        fullWidth
                        sx={{
                            '& .MuiInputLabel-root': {
                                color: 'black',
                            },
                            my: 2,
                        }}
                    />
                    <Button
                        variant="contained"
                        onClick={() => addPresaleCodeMutation()}
                        fullWidth
                        sx={{ backgroundColor: '#204391', color: 'white' }}
                    >
                        Save
                    </Button>
                </DialogContent>
            </Dialog>
            {isLoading ? (
                <Loader />
            ) : (
                <Box
                    sx={{
                        bgcolor: 'background.white',
                        p: '25px',
                        borderRadius: '12px',
                        display: 'flex',
                        flexDirection: 'column',
                        width: '900px',
                        maxHeight: '85%',
                        overflowY: 'scroll',
                        gap: '20px',
                        zIndex: '2500',
                    }}
                >
                    <Box sx={{ display: 'flex', gap: '20px' }}></Box>
                    <Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                gap: '15px',
                                mb: '20px',
                            }}
                        >
                            <Avatar src={item?.images[0]} sx={{ width: 56, height: 56, objectFit: 'cover' }} />
                            <Typography sx={{ fontSize: '24px', fontWeight: '500' }}>{item?.name}</Typography>
                            {permissions?.includes(USER_PERMISSIONS.MANAGE_PRESALE_CODES) && (
                                <Button
                                    onClick={() => setIsAddingCodeModalOpen(true)}
                                    sx={{ color: 'blue', marginLeft: 'auto' }}
                                >
                                    Add new code
                                </Button>
                            )}
                        </Box>

                        <Box sx={{ display: 'grid', gridTemplateColumns: '3fr' }}>
                            <Box>
                                <Box
                                    sx={{
                                        display: 'grid',
                                        gridTemplateColumns: '1fr 1fr 1fr',
                                        columnGap: '65px',
                                    }}
                                >
                                    {presalesWithDateArr && presalesWithDateArr.length > 0 && (
                                        <>
                                            <Typography sx={{ fontSize: '22px' }}>Presale Date</Typography>
                                            <Typography sx={{ fontSize: '22px' }}>Name</Typography>
                                            <Typography sx={{ fontSize: '22px' }}>Presale Code</Typography>
                                        </>
                                    )}
                                </Box>

                                <Box
                                    sx={{
                                        display: 'grid',
                                        // gridTemplateColumns: '1fr 1fr',
                                        gap: '10px',
                                    }}
                                >
                                    {presalesWithDateArr.map((presales, index, presalesArr) => (
                                        <Box
                                            key={index}
                                            sx={{
                                                display: 'grid',
                                                alignItems: 'center',
                                                gridTemplateColumns: '1fr 1fr 1fr',
                                                gap: '65px',
                                            }}
                                        >
                                            <Typography sx={{ fontSize: '16px', color: 'grey.600' }}>
                                                {formatCustomDate(presales.startDateTime, timezone)}
                                            </Typography>
                                            <Typography sx={{ fontSize: '16px', color: 'grey.600' }}>
                                                {presales?.name}
                                            </Typography>
                                            <Stack>
                                                <Stack>
                                                    {presales.codes.map(({ name, code }) => (
                                                        <Typography
                                                            sx={{
                                                                fontSize: '16px',
                                                                color: 'grey.600',
                                                                width: '100%',
                                                            }}
                                                        >
                                                            {`${name}=`}
                                                            <Typography
                                                                component="span"
                                                                onClick={() => {
                                                                    if (code) {
                                                                        navigator.clipboard
                                                                            .writeText(code)
                                                                            .then(() => {
                                                                                toast(
                                                                                    'Presale code copied to clipboard'
                                                                                )
                                                                            })
                                                                            .catch((err) => {
                                                                                FrontEndError(err)
                                                                            })
                                                                    }
                                                                }}
                                                                sx={{ fontSize: '16px', color: 'blue' }}
                                                            >
                                                                {code}
                                                                <IconButton>
                                                                    <ContentCopyIcon
                                                                        sx={{
                                                                            color: 'grey.600',
                                                                            fontSize: '16px',
                                                                        }}
                                                                    />
                                                                </IconButton>
                                                            </Typography>
                                                        </Typography>
                                                    ))}
                                                </Stack>
                                            </Stack>
                                        </Box>
                                    ))}
                                    {otherCodes && otherCodes.length > 0 && (
                                        <Box
                                            sx={{
                                                display: 'grid',
                                                alignItems: 'center',
                                                gridTemplateColumns: '1fr 1fr 1fr',
                                                gap: '65px',
                                            }}
                                        >
                                            <Typography sx={{ fontSize: '16px', color: 'grey.600' }}></Typography>
                                            <Typography sx={{ fontSize: '16px', color: 'grey.600' }}></Typography>
                                            <Stack
                                                alignItems="center"
                                                sx={{
                                                    p: '5px',
                                                    borderRadius: '12px',
                                                    border: '1px solid #ccc',
                                                    height: '-webkit-fill-available',
                                                }}
                                            >
                                                {otherCodes.map((presale) => (
                                                    <Box>
                                                        {presale.codes.map((code) => (
                                                            <Typography
                                                                sx={{
                                                                    fontSize: '16px',
                                                                    color: 'grey.600',
                                                                    width: '100%',
                                                                }}
                                                            >
                                                                {`${code.name}=`}
                                                                <Typography
                                                                    component="span"
                                                                    onClick={() => {
                                                                        if (code.code) {
                                                                            navigator.clipboard
                                                                                .writeText(code.code)
                                                                                .then(() => {
                                                                                    toast(
                                                                                        'Presale code copied to clipboard'
                                                                                    )
                                                                                })
                                                                                .catch((err) => {
                                                                                    FrontEndError(err)
                                                                                })
                                                                        }
                                                                    }}
                                                                    sx={{ fontSize: '16px', color: 'blue' }}
                                                                >
                                                                    {code.code}
                                                                    <IconButton>
                                                                        <ContentCopyIcon
                                                                            sx={{ color: 'grey.600', fontSize: '16px' }}
                                                                        />
                                                                    </IconButton>
                                                                </Typography>
                                                            </Typography>
                                                        ))}
                                                    </Box>
                                                ))}
                                            </Stack>
                                        </Box>
                                    )}

                                    <Box
                                        sx={{
                                            display: 'grid',
                                            alignItems: 'center',
                                            gridTemplateColumns: '1fr 1fr',
                                            gap: '65px',
                                            mt: '10px',
                                        }}
                                    >
                                        <Typography sx={{ fontSize: '22px' }}>Public Sale Date</Typography>
                                        <Typography sx={{ fontSize: '16px', color: 'black' }}>
                                            {formatCustomDate(item?.saleStartDate, timezone)}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Button
                        onClick={() => setOpen(false)}
                        sx={{
                            bgcolor: 'action.active',
                            color: 'text.default',
                            borderRadius: '5px',
                            alignSelf: 'end',
                            ':hover': {
                                bgcolor: 'action.active',
                                color: 'text.default',
                            },
                        }}
                    >
                        CLOSE
                    </Button>
                </Box>
            )}
        </>
    )
}
