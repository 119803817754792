export const categories = ['Music', 'Comad', 'Brodway', 'Others', 'All']

export const countries = ['US', 'CA']

export const headers = [
    'Event',
    'Ranking',
    'Date',
    'Venue',
    'Location',
    'Capacity',
    'Presale/Public Sale',
    'Quick Stats',
    'Ticket Limit & Price Range',
    'ACTION',
]

export const defaultSliderValue = {
    spotifyRankValue: [0, 100],
    capacityValue: [0, 100000],
    priceValue: [0, 400],
    primaryValue: [0, 4000],
    secondaryValue: [0, 4000],
    sellouts: [0, 70],
}

export const EventSelectArray = [
    {
        name: 'all_presales',
        tag: 'All Presales',
    },
    {
        name: 'all_public_sale',
        tag: 'All public sales',
    },
    {
        name: 'first_presale',
        tag: 'First Presale',
    },
    {
        name: 'public_without_presale',
        tag: 'Public sale Without any Presale ( First Public sale)',
    },
    // {
    //     name: 'sale_after_presale',
    //     tag: 'Public sale after Presale',
    // },
]

export const platformSelectArray = [
    { name: 'ticketmaster', tag: 'Ticketmaster' },
    { name: 'axs', tag: 'AXS' },
    { name: 'livenation', tag: 'Live Nation' },
    { name: 'other', tag: 'Other' },
]

export const timeSelectArray = [
    { name: '0:00:00-23:59:59', tag: 'All' },
    { name: '0:00:00-10:00:00', tag: 'Before 10 AM EST' },
    { name: '10:00:00-11:00:00', tag: '10:00 AM - 11:00 AM EST' },
    { name: '11:00:00-12:00:00', tag: '11:00 AM - 12:00 PM EST' },
    { name: '12:00:00-13:00:00', tag: '12:00 PM - 1:00 PM EST' },
    { name: '13:00:00-14:00:00', tag: '1:00 PM - 2:00 PM EST' },
    { name: '14:00:00-00:00:00', tag: 'After 2:00 PM EST' },
]
