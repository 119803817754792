export const headers = [
    { name: 'Event' },
    { name: 'Rank' },
    { name: 'Date' },
    { name: 'Venue' },
    { name: 'Location' },
    { name: 'Capacity' },
    { name: 'Primary Availability' },
    { name: 'Secondary Availability' },
    { name: 'Primary Price', infoIcon: 'primaryPriceInfoModal' },
    { name: 'Secondary Get In', infoIcon: 'getInPriceInfoModal' },
    { name: 'Actions' },
]
