import { isNumber, isUndefined } from 'lodash'
import { useMemo } from 'react'
import { EventType, IPreparedEventType } from 'src/types/local/customTableTypes'
import { otherPlatform, secondaryOtherPlatform } from 'src/types/local/upSalesResp'
import numberWithCommas from 'src/utils/numberWithCommas'

export const processEvent = (event: EventType): IPreparedEventType => {
    const { primaryMetaPlatform, secondaryMetaPlatform, isAXS } = getPlatformsInfo(event.meta.otherPlatforms)

    const isSportCategory = event.category === 'Sports'

    const formattedCapacity = getCapacity({
        capacity: event.capacity,
        primaryMetaPlatform,
        secondaryMetaPlatform,
    })

    const primaryPrices = {
        min: isNumber(event.priceRange[0]?.min) ? event?.priceRange[0]?.min : null,
        max: isNumber(event.priceRange[0]?.max) ? event?.priceRange[0]?.max : null,
    }

    const secondaryPrices = {
        singleCheapest:
            (isNumber(event.last_fetched_details?.secondary_minimum_price) &&
                event.last_fetched_details?.secondary_minimum_price) ||
            null,
        multiplyCheapest:
            (isNumber(event.last_fetched_details?.secondary_get_in_price2) &&
                event.last_fetched_details?.secondary_get_in_price2) ||
            null,
    }

    const primaryTicketsCount = isNumber(event.last_fetched_details?.primary_tickets_count)
        ? event.last_fetched_details?.primary_tickets_count
        : null

    const secondaryTicketsCount = isNumber(event.last_fetched_details?.secondary_tickets_count)
        ? event.last_fetched_details?.secondary_tickets_count
        : null

    let lastFetchedAtUTC: string
    if (event.last_fetched_at) {
        lastFetchedAtUTC = event?.last_fetched_at.endsWith('Z') ? event?.last_fetched_at : `${event?.last_fetched_at}Z`
    }

    let stubhubLastFetchedAtUTC: string
    if (event.stubhub_last_fetched_at) {
        stubhubLastFetchedAtUTC = event?.stubhub_last_fetched_at.endsWith('Z')
            ? event?.stubhub_last_fetched_at
            : `${event?.stubhub_last_fetched_at}Z`
    }

    let axsLastFetchedAtUTC: string
    if (event.axs_last_fetched_at) {
        axsLastFetchedAtUTC = event?.axs_last_fetched_at.endsWith('Z')
            ? event?.axs_last_fetched_at
            : `${event?.axs_last_fetched_at}Z`
    }

    return {
        ...event,
        primaryMetaPlatform,
        secondaryMetaPlatform,
        isAXS,
        isSportCategory,
        formattedCapacity,
        primaryPrices,
        secondaryPrices,
        primaryTicketsCount,
        secondaryTicketsCount,
        lastFetchedAtUTC,
        stubhubLastFetchedAtUTC,
        axsLastFetchedAtUTC,
    }
}

export const useSingleEvent = (event: EventType): IPreparedEventType => {
    return useMemo(() => {
        return processEvent(event)
    }, [event])
}

export const useEvents = (data: EventType[]): { eventData: IPreparedEventType[] } => {
    const eventData = useMemo(() => {
        return data.map((event) => processEvent(event))
    }, [data])

    return {
        eventData,
    }
}

const getPlatformsInfo = (
    platforms: otherPlatform[]
): {
    primaryMetaPlatform: otherPlatform | null
    secondaryMetaPlatform: secondaryOtherPlatform | null
    isAXS: boolean
} => {
    let primaryMetaPlatform = null
    let primaryOtherPlatform = null
    let secondaryMetaPlatform = null
    let isAXS = false

    platforms.forEach((platform) => {
        if (!primaryMetaPlatform && platform.type === 'Primary') {
            primaryMetaPlatform = platform
        }

        if (!primaryMetaPlatform && platform.type === 'Primary Others') {
            primaryOtherPlatform = platform
        }

        if (!secondaryMetaPlatform && (platform.type === 'Secondary' || platform.type?.at(0) === 'Secondary')) {
            secondaryMetaPlatform = platform
        }

        if (!isAXS && platform.marketPlace === 'axs') {
            isAXS = true
        }
    })

    if (!primaryMetaPlatform && primaryOtherPlatform) {
        primaryMetaPlatform = primaryOtherPlatform
    }

    return { primaryMetaPlatform, secondaryMetaPlatform, isAXS }
}

const getCapacity = ({
    capacity,
    primaryMetaPlatform,
    secondaryMetaPlatform,
}: {
    capacity: number | null
    primaryMetaPlatform: otherPlatform | null
    secondaryMetaPlatform: otherPlatform | null
}) => {
    return numberWithCommas(
        (() => {
            if (!isUndefined(capacity)) {
                return capacity
            }
            if (
                primaryMetaPlatform &&
                primaryMetaPlatform.type === 'Primary' &&
                primaryMetaPlatform.platform === 'ticketmaster' &&
                primaryMetaPlatform.totalPlaces > 99
            ) {
                return primaryMetaPlatform.totalPlaces
            }

            if (secondaryMetaPlatform?.totalPlaces) {
                return secondaryMetaPlatform.totalPlaces
            }

            if (
                primaryMetaPlatform &&
                primaryMetaPlatform.type === 'Primary Others' &&
                primaryMetaPlatform.totalPlaces
            ) {
                return primaryMetaPlatform.totalPlaces
            }
        })()
    )
}
