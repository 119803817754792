import { isUndefined } from 'lodash'
import { TTier } from 'src/types/api/apiTierManagement'
import { TUserAdminPermissions } from 'src/types/local/adminPermissions'
import axiosClient from 'src/utils/axios'
import { FrontEndError } from 'src/utils/error'

interface getUsers {
    perPage?: number | string
    page?: number | string
    subType: string
    username?: string
}

interface getEvents {
    perPage?: number | string
    page?: number | string
    filter: string
    eventSearch: string
}

interface getVenueMismatchEvents {
    perPage?: number | string
    page?: number | string
    eventSearch: string
    showDistinctValues: boolean
}

interface IUpdateUser {
    userId: string
    username?: string
    phoneNumber?: string
    isAdmin?: boolean
    permissions?: TUserAdminPermissions[]
    activated?: boolean
}

interface IUpdateEvent {
    eventId: string
    capacity?: number
    rank?: number
    artistsStat?: any[]
    currentStatus?: 'cancelled' | 'offsale' | 'onsale' | 'postponed' | 'rescheduled'
}

interface IUpdateUserPaymentPlan {
    userId: string
    planId: string
    exp_date?: string
    sub_date?: string
}

interface IMergeEvents {
    primaryEventUrl: string
    secondaryEventUrl: string
}

interface ICreateTier {
    name?: string
    description?: string
    price?: number
    validity?: number
    total_allowed_fetch?: number

    primaryFavorites?: number
    TMDropchecker?: number
    AXSDropchecker?: number
    TMLowInventoryAlert?: number
    AXSLowInventoryAlert?: number
    SecondaryFavorites?: number
    PriceDropAlert?: number

    extensionDataAccess?: boolean
    extensionMapAccess?: boolean
}

interface IUpdateTier extends ICreateTier {
    tierId: string
}

interface IUpdateSeatGroup {
    seatGroupId: string
    name?: string
    seatType?: string[]
}

interface ICreateSeatGroup {
    name?: string
    seatType?: string[]
}

class AdminApi {
    async getUser(userId: string) {
        const url = `api/admin/user/get?userId=${userId}`
        try {
            const res = await axiosClient().get(url)
            return res.data
        } catch (err) {
            FrontEndError(err)
            const error = {
                isError: true,
                errorMessage: err.response.data.Message || 'Internal server error',
            }
            return error
        }
    }
	
	async updateSeatGroup({ seatGroupId, name, seatType }: IUpdateSeatGroup) {
        const url = `/api/seat-group/${seatGroupId}`
        try {
            const update = {
                ...(!isUndefined(name) ? { name } : {}),
                ...(!isUndefined(seatType) ? { seatType } : {}),
            }
            const res = await axiosClient().put(url, update)
            return res.data
        } catch (err) {
            FrontEndError(err)
            const error = {
                isError: true,
                errorMessage: err.response.data.Message || 'Internal server error',
            }
            return error
        }
    }
    
    async updateUser({ userId, username, phoneNumber, isAdmin, permissions, activated }: IUpdateUser) {
        const url = `api/admin/user/update?userId=${userId}`
        try {
            const update = {
                ...(!isUndefined(username) ? { username } : {}),
                ...(!isUndefined(phoneNumber) ? { phoneNumber } : {}),
                ...(!isUndefined(isAdmin) ? { isAdmin } : {}),
                ...(!isUndefined(permissions) ? { permissions } : {}),
                ...(!isUndefined(activated) ? { activated } : {}),
            }
            await axiosClient().put(url, update)
        } catch (err) {
            FrontEndError(err)
            const error = {
                isError: true,
                errorMessage: err.response.data.Message || 'Internal server error',
            }
            return error
        }
    }

    async getUsersPaymentPlanInfo({ subType, perPage, page, username }: getUsers) {
        let url = `/api/admin/users/subscribed?filter=${subType}&page=${page}&per_page=${perPage}`

        if (username) {
            url = url + `&username=${username}`
        }

        try {
            const res = await axiosClient().get(url)
            return res.data
        } catch (err) {
            FrontEndError(err)
            const error = {
                isError: true,
                errorMessage: err.response.data.Message || 'Internal server error',
            }
            return error
        }
    }

    async getUserPrimaryFavorites(userId: string) {
        const url = `/api/admin/user/events/favorite/primary?userId=${userId}`
        try {
            const res = await axiosClient().get(url)
            return res.data
        } catch (err) {
            FrontEndError(err)
            const error = {
                isError: true,
                errorMessage: err.response.data.Message || 'Internal server error',
            }
            return error
        }
    }

    async getUserSecondaryFavorites(userId: string) {
        const url = `/api/admin/user/events/favorite/secondary?userId=${userId}`
        try {
            const res = await axiosClient().get(url)
            return res.data
        } catch (err) {
            FrontEndError(err)
            const error = {
                isError: true,
                errorMessage: err.response.data.Message || 'Internal server error',
            }
            return error
        }
    }

    async getUserPriceDropAlerts(userId: string) {
        const url = `/api/admin/user/price-drop-alerts?userId=${userId}`
        try {
            const res = await axiosClient().get(url)
            return res.data.data
        } catch (err) {
            FrontEndError(err)
            const error = {
                isError: true,
                errorMessage: err.response.data.Message || 'Internal server error',
            }
            return error
        }
    }

    async getUserDropCheckerAlerts(userId: string) {
        const url = `/api/admin/user/drop-checker-alerts?userId=${userId}`
        try {
            const res = await axiosClient().get(url)
            return res.data.data
        } catch (err) {
            FrontEndError(err)
            const error = {
                isError: true,
                errorMessage: err.response.data.Message || 'Internal server error',
            }
            return error
        }
    }

    async GetUserLowInventoryAlerts(userId: string) {
        const url = `/api/admin/user/low-inventory-alerts?userId=${userId}`
        try {
            const res = await axiosClient().get(url)
            return res.data.data
        } catch (err) {
            FrontEndError(err)
            const error = {
                isError: true,
                errorMessage: err.response.data.Message || 'Internal server error',
            }
            return error
        }
    }

    async UpdateEvent({ eventId, capacity, rank, artistsStat, currentStatus }: IUpdateEvent) {
        const url = `/api/admin/event/update?eventId=${eventId}`
        try {
            const update = {
                ...(capacity ? { 'meta.otherPlatforms.$[].totalPlaces': capacity } : {}),
                ...(rank ? { popularity: rank } : {}),
                ...(artistsStat ? { artistsStat: artistsStat } : {}),
                ...(!isUndefined(currentStatus) ? { currentStatus: currentStatus } : {}),
            }
            const res = await axiosClient().put(url, update)
            return res.data
        } catch (err) {
            FrontEndError(err)
            const error = {
                isError: true,
                errorMessage: err.response.data.Message || 'Internal server error',
            }
            return error
        }
    }

    async getPaymentPlans() {
        const url = `/api/payment-plans/get`
        try {
            const res = await axiosClient().get(url)
            return res.data
        } catch (err) {
            FrontEndError(err)
            const error = {
                isError: true,
                errorMessage: err.response.data.Message || 'Internal server error',
            }
            return error
        }
    }

    async updateUserPaymentPlan({ userId, planId, exp_date, sub_date }: IUpdateUserPaymentPlan) {
        const url = `/api/admin/user/payment-plan/update?userId=${userId}&planId=${planId}`
        try {
            const res = await axiosClient().put(url, {
                ...(exp_date ? { exp_date } : {}),
                ...(sub_date ? { sub_date } : {}),
            })
            return res.data
        } catch (err) {
            FrontEndError(err)
            const error = {
                isError: true,
                errorMessage: err.response.data.Message || 'Internal server error',
            }
            return error
        }
    }

    async mergeEvents({ primaryEventUrl, secondaryEventUrl }: IMergeEvents) {
        const url = `/api/admin/event/merge?pri_event_url=${primaryEventUrl}&sec_event_url=${secondaryEventUrl}`
        try {
            const res = await axiosClient().get(url)
            return res.data
        } catch (err) {
            FrontEndError(err)
            const error = {
                isError: true,
                errorMessage: err.response.data.Message || 'Internal server error',
            }
            return error
        }
    }

    async getTiers(): Promise<TTier[] | any> {
        const url = `/api/payment-plans/get`
        try {
            const res = await axiosClient().get(url)
            return res.data
        } catch (err) {
            FrontEndError(err)
            const error = {
                isError: true,
                errorMessage: err.response.data.Message || 'Internal server error',
            }
            return error
        }
    }

    async updateTier({
        tierId,
        name,
        description,
        price,
        validity,
        total_allowed_fetch,
        primaryFavorites,
        TMDropchecker,
        AXSDropchecker,
        TMLowInventoryAlert,
        AXSLowInventoryAlert,
        SecondaryFavorites,
        PriceDropAlert,

        extensionDataAccess,
        extensionMapAccess,
    }: IUpdateTier) {
        const url = `/api/payment-plan/update/${tierId}`
        try {
            const update = {
                ...(!isUndefined(name) ? { name } : {}),
                ...(!isUndefined(description) ? { description } : {}),
                ...(!isUndefined(price) ? { price } : {}),
                ...(!isUndefined(validity) ? { validity } : {}),
                ...(!isUndefined(total_allowed_fetch) ? { total_allowed_fetch } : {}),

                ...(!isUndefined(primaryFavorites) ? { primaryFavorites } : {}),
                ...(!isUndefined(TMDropchecker) ? { TMDropchecker } : {}),
                ...(!isUndefined(AXSDropchecker) ? { AXSDropchecker } : {}),
                ...(!isUndefined(TMLowInventoryAlert) ? { TMLowInventoryAlert } : {}),
                ...(!isUndefined(AXSLowInventoryAlert) ? { AXSLowInventoryAlert } : {}),
                ...(!isUndefined(SecondaryFavorites) ? { SecondaryFavorites } : {}),
                ...(!isUndefined(PriceDropAlert) ? { PriceDropAlert } : {}),

                ...(!isUndefined(extensionDataAccess) ? { extensionDataAccess } : {}),
                ...(!isUndefined(extensionMapAccess) ? { extensionMapAccess } : {}),
            }
            const res = await axiosClient().put(url, update)
            return res.data
        } catch (err) {
            FrontEndError(err)
            const error = {
                isError: true,
                errorMessage: err.response.data.Message || 'Internal server error',
            }
            return error
        }
    }

    async createTier({
        name,
        description,
        price,
        validity,
        total_allowed_fetch,
        primaryFavorites,
        TMDropchecker,
        AXSDropchecker,
        TMLowInventoryAlert,
        AXSLowInventoryAlert,
        SecondaryFavorites,
        PriceDropAlert,
    }: ICreateTier) {
        const url = '/api/payment-plan/create'
        try {
            const update = {
                ...(!isUndefined(name) ? { name } : {}),
                ...(!isUndefined(description) ? { description } : {}),
                ...(!isUndefined(price) ? { price } : {}),
                ...(!isUndefined(validity) ? { validity } : {}),
                ...(!isUndefined(total_allowed_fetch) ? { total_allowed_fetch } : {}),

                ...(!isUndefined(primaryFavorites) ? { primaryFavorites } : {}),
                ...(!isUndefined(TMDropchecker) ? { TMDropchecker } : {}),
                ...(!isUndefined(AXSDropchecker) ? { AXSDropchecker } : {}),
                ...(!isUndefined(TMLowInventoryAlert) ? { TMLowInventoryAlert } : {}),
                ...(!isUndefined(AXSLowInventoryAlert) ? { AXSLowInventoryAlert } : {}),
                ...(!isUndefined(SecondaryFavorites) ? { SecondaryFavorites } : {}),
                ...(!isUndefined(PriceDropAlert) ? { PriceDropAlert } : {}),
            }
            const res = await axiosClient().post(url, update)
            return res.data
        } catch (err) {
            FrontEndError(err)
            const error = {
                isError: true,
                errorMessage: err.response.data.Message || 'Internal server error',
            }
            return error
        }
    }
    
    async createSeatGroup({
        name,
        seatType
    }: ICreateSeatGroup) {
        const url = '/api/seat-group/create'
        try {
            const update = {
                ...(!isUndefined(name) ? { name } : {}),
                ...(!isUndefined(seatType) ? { seatType } : {}),
            }
            const res = await axiosClient().post(url, update)
            return res.data
        } catch (err) {
            FrontEndError(err)
            const error = {
                isError: true,
                errorMessage: err.response.data.Message || 'Internal server error',
            }
            return error
        }
    }

    async removeTier(tierId: string) {
        const url = `/api/payment-plan/delete/${tierId}`
        try {
            const res = await axiosClient().delete(url)
            return res.data
        } catch (err) {
            FrontEndError(err)
            const error = {
                isError: true,
                errorMessage: err.response.data.Message || 'Internal server error',
            }
            return error
        }
    }
    
    async deleteSeatGroup(seatGroupId: string) {
        const url = `/api/seat-group/${seatGroupId}`
        try {
            const res = await axiosClient().delete(url)
            return res.data
        } catch (err) {
            FrontEndError(err)
            const error = {
                isError: true,
                errorMessage: err.response.data.Message || 'Internal server error',
            }
            return error
        }
    }

    async addToWatchlist(eventId: string) {
        const url = `/api/admin/watchlist`
        try {
            const res = await axiosClient().post(url, { event_id: eventId })
            return res.data
        } catch (err) {
            FrontEndError(err)
            const error = {
                isError: true,
                errorMessage: err.response.data.Message || 'Internal server error',
            }
            return error
        }
    }

    async addToParser(keywords: string[], source) {
        const url = `/api/admin/presale/manual-add-presale`
        try {
            const res = await axiosClient().post(url, { keywords, source })
            return res.data
        } catch (err) {
            FrontEndError(err)
            const error = {
                isError: true,
                errorMessage: err.response.data.Message || 'Internal server error',
            }
            return error
        }
    }

    async getParserList() {
        const url = `/api/admin/presale/manual-add-presale/all`
        try {
            const res = await axiosClient().get(url)
            return res.data
        } catch (err) {
            FrontEndError(err)
            const error = {
                isError: true,
                errorMessage: err.response.data.Message || 'Internal server error',
            }
            return error
        }
    }

    async removeFromParserList(id) {
        const url = `/api/admin/presale/manual-add-presale/remove/${id}`
        try {
            const res = await axiosClient().delete(url)
            return res.data
        } catch (err) {
            FrontEndError(err)
            const error = {
                isError: true,
                errorMessage: err.response.data.Message || 'Internal server error',
            }
            return error
        }
    }

    async deleteUser(id) {
        const url = `/api/admin/user/delete/${id}`
        try {
            const res = await axiosClient().delete(url)
            return res.data
        } catch (err) {
            FrontEndError(err)
            const error = {
                isError: true,
                errorMessage: err.response.data.Message || 'Internal server error',
            }
            return error
        }
    }

    async addPresaleCode(eventId: string, name: string, code: string) {
        const url = '/api/admin/event/add-presale-code'
        try {
            const res = await axiosClient().post(url, { eventId, name, code })
            return res.data
        } catch (err) {
            FrontEndError(err)
            const error = {
                isError: true,
                errorMessage: err.response.data.Message || 'Internal server error',
            }
            return error
        }
    }

    async getVenuesStats() {
        const url = '/api/admin/venues/scraping/stats'
        try {
            const res = await axiosClient().get(url)
            return res.data
        } catch (err) {
            FrontEndError(err)
            const error = {
                isError: true,
                errorMessage: err.response.data.message || 'Internal server error',
            }
            return error
        }
    }

    async getVenuesWithError({ perPage, page, venueSearch, countryCodeSearch, timezoneSearch, urlSearch, statusArr }) {
        let url = `/api/venues/all?per_page=${perPage}&page=${page}`
        if (venueSearch.length) {
            url = `${url}&name=${venueSearch}`
        }
        if (countryCodeSearch.length) {
            url = `${url}&country_code=${countryCodeSearch}`
        }
        if (timezoneSearch.length) {
            url = `${url}&timezone=${timezoneSearch}`
        }
        if (urlSearch.length) {
            url = `${url}&url=${urlSearch}`
        }
        if (statusArr.length) {
            url = `${url}&status=${statusArr.join(',')}`
        }
        try {
            const res = await axiosClient().get(url)
            return res.data
        } catch (err) {
            FrontEndError(err)
            const error = {
                isError: true,
                errorMessage: err.response.data.message || 'Internal server error',
            }
            return error
        }
    }

    async setMatch({ venueId, tmUrl, stubhubUrl }: { venueId: string; tmUrl?: string; stubhubUrl?: string }) {
        let url = `/api/venues/set/url?id=${venueId}`
        if (tmUrl) {
            url = url + `&url=${tmUrl}`
        }
        if (stubhubUrl) {
            url = url + `&stubhub_url=${stubhubUrl}`
        }
        try {
            const res = await axiosClient().get(url)
            return res.data
        } catch (err) {
            FrontEndError(err)
            const error = {
                isError: true,
                errorMessage: err.response.data.message || 'Internal server error',
            }
            return error
        }
    }
    
    
    async setVenueUrlsStubhub({ venueId, urls }: { venueId: string; urls: string[] }) {
        const url = `/api/venues/update/stubhub-venue-url/${venueId}`
        try {
            const update = {
                ...(!isUndefined(urls) ? { urls } : {}),
            }
            const res = await axiosClient().put(url, update)
            return res.data
        } catch (err) {
            FrontEndError(err)
            const error = {
                isError: true,
                errorMessage: err.response.data.Message || 'Internal server error',
            }
            return error
        }
    }
    


    async getVenueInfo({ venueUrl }: { venueUrl: string }) {
        const url = `/api/admin/venues/scraping/url`
        try {
            const res = await axiosClient().post(url, { url: venueUrl })
            return res.data
        } catch (err) {
            FrontEndError(err)
            const error = {
                isError: true,
                errorMessage: err.response.data.message || 'Internal server error',
            }
            return error
        }
    }
    
    async getSeatGroups({ search }: { search?: string } = {}) {
    	let url = `/api/seat-group`
    	if (search) {
    		url = `/api/seat-group?search=${search}`
    	}
        
        try {
            const res = await axiosClient().get(url)
            return res.data
        } catch (err) {
            FrontEndError(err)
            const error = {
                isError: true,
                errorMessage: err.response.data.message || 'Internal server error',
            }
            return error
        }
    }
    
    async getSeatTypesFromDB() {
        const url = `/api/admin/event/unique-seat-types`
        try {
            const res = await axiosClient().get(url)
            return res.data
        } catch (err) {
            FrontEndError(err)
            const error = {
                isError: true,
                errorMessage: err.response.data.message || 'Internal server error',
            }
            return error
        }
    }
    
    async getEventMatch({ filter, perPage, page, eventSearch }: getEvents) {
    	let url = `/api/admin/event/categorize?page=${page}&per_page=${perPage}`
    	if (filter) {
    		url = url + `&filter=${filter}`
    	}
    	
        
        //Check if eventSearch is not empty or just whitespace
		if (eventSearch.length > 0) {
		    url = url + `&searchable=${eventSearch}`
		}
		
        try {
            const res = await axiosClient().get(url)
            return res.data
        } catch (err) {
            FrontEndError(err)
            const error = {
                isError: true,
                errorMessage: err.response.data.Message || 'Internal server error',
            }
            return error
        }
    }
    
    async getVenueMismatch({ perPage, page, eventSearch, showDistinctValues }: getVenueMismatchEvents) {
    	let intShowDistinctValues;
    	if (showDistinctValues === true) {
    		intShowDistinctValues = 1
    	}
    	else {
    		intShowDistinctValues = 0
    	}
    	let url = `/api/admin/event/venue-url-mismatch?distinct=${intShowDistinctValues}&page=${page}&per_page=${perPage}`	
        
        //Check if eventSearch is not empty or just whitespace
		if (eventSearch.length > 0) {
		    url = url + `&searchable=${eventSearch}`
		}
		
        try {
            const res = await axiosClient().get(url)
            return res.data
        } catch (err) {
            FrontEndError(err)
            const error = {
                isError: true,
                errorMessage: err.response.data.Message || 'Internal server error',
            }
            return error
        }
    }

    async addVenue({
        addressLine,
        capacity,
        cityName,
        countryCode,
        name,
        postalCode,
        stateCode,
        stubhubUrl,
        url: tmUrl,
        timezone,
    }: {
        addressLine: string
        capacity: number
        cityName: string
        countryCode: string
        name: string
        postalCode: string
        stateCode: string
        stubhubUrl: string
        url: string
        timezone: string
    }) {
        const url = '/api/admin/venues/confirm/data'
        try {
            const res = await axiosClient().post(url, {
                addressLine,
                capacity,
                cityName,
                countryCode,
                name,
                postalCode,
                stateCode,
                stubhubUrl,
                url: tmUrl,
                timezone,
            })
            return res.data
        } catch (err) {
            // FrontEndError(err)
            const error = {
                isError: true,
                errorMessage: err.response.data.message || 'Internal server error',
            }
            return error
        }
    }
}

export const adminApi = new AdminApi()
