import { Suspense, lazy } from 'react'
import { Navigate, Outlet, RouteObject } from 'react-router'
import { JSX } from 'react/jsx-runtime'
import DashboardLayout from '../components/dashboard/layout/DashboardLayout'
import AuthGuard from '../components/general/AuthGuard'
import GuestGuard from '../components/general/GuestGuard'
import LoadingScreen from '../components/general/LoadingScreen'
import MessageSent from './authentication/MessageSent'
import BuyingList from './dashboard/BuyingList'
import EventAnalyticsComponent from './dashboard/EventAnalytics'
import WatchList from './dashboard/WatchList'
import PATHS from '../common/constants/paths'

const Loadable = (Component) => (props: JSX.IntrinsicAttributes) => (
    <Suspense fallback={<LoadingScreen />}>
        <Component {...props} />
    </Suspense>
)

// Common
const NotFound = Loadable(lazy(() => import('./common/NotFound')))
const ServerError = Loadable(lazy(() => import('./common/ServerError')))
const NoConnection = Loadable(lazy(() => import('./common/NoConnection')))

// Authentication
const Login = Loadable(lazy(() => import('./authentication/Login')))
const PasswordRecovery = Loadable(lazy(() => import('./authentication/PasswordRecovery')))
const PasswordReset = Loadable(lazy(() => import('./authentication/PasswordReset')))
const Register = Loadable(lazy(() => import('./authentication/Register')))
const VerifyCode = Loadable(lazy(() => import('./authentication/VerifyCode')))

// Dashboard
const ArtistMetric = Loadable(lazy(() => import('./dashboard/ArtistMetric')))
const Overview = Loadable(lazy(() => import('./dashboard/Overview')))
const Metric = Loadable(lazy(() => import('./dashboard/Metric')))
const PrimaryTopMovers = Loadable(lazy(() => import('./dashboard/PrimaryTopMovers')))
const SecondaryTopMovers = Loadable(lazy(() => import('./dashboard/SecondaryTopMovers')))
const SecondaryTopSelling = Loadable(lazy(() => import('./dashboard/SecondaryTopSelling')))
const PrimaryFavorites = Loadable(lazy(() => import('./dashboard/PrimaryFavorites')))
const SecondaryFavorites = Loadable(lazy(() => import('./dashboard/SecondaryFavorites')))
const PrimaryAnalytics = Loadable(lazy(() => import('./dashboard/PrimaryAnalytics')))
const SecondaryAnalytics = Loadable(lazy(() => import('./dashboard/SecondaryAnalytics')))

// User
const UserDashboard = Loadable(lazy(() => import('./user/dashboard')))
const ExtensionPage = Loadable(lazy(() => import('./dashboard/extension')))
const AlertsPage = Loadable(lazy(() => import('./dashboard/alerts')))
const AcademyPage = Loadable(lazy(() => import('./dashboard/academy')))
const SupportPage = Loadable(lazy(() => import('./dashboard/support')))
const CommunityPage = Loadable(lazy(() => import('./dashboard/community')))

// Admin
const Admin = Loadable(lazy(() => import('./dashboard/admin/Admin')))
const SubscriptionManagement = Loadable(lazy(() => import('./dashboard/admin/SubscriptionManagement')))
const TierManagement = Loadable(lazy(() => import('./dashboard/admin/TierManagement')))
const VenueMatch = Loadable(lazy(() => import('./dashboard/admin/VenueMatch')))
const EventMatch = Loadable(lazy(() => import('./dashboard/admin/EventMatch')))
const VenueMismatch = Loadable(lazy(() => import('./dashboard/admin/VenueMismatch')))
const SeatGroups = Loadable(lazy(() => import('./dashboard/admin/SeatGroups')))
const VenueUrls = Loadable(lazy(() => import('./dashboard/admin/VenueUrls')))

const routes: RouteObject[] = [
    {
        path: `${PATHS.ROOT}`,
        element: <Navigate to={`${PATHS.DASHBOARD.UPCOMING.ROOT}`} replace />,
    },
    {
        path: '/*',
        element: <Navigate to={`${PATHS.NOT_FOUND}`} replace />,
    },
    {
        path: `${PATHS.NOT_FOUND}`,
        element: <NotFound />,
    },
    {
        path: `${PATHS.MAINTENANCE}`,
        element: <ServerError />,
    },
    {
        path: `${PATHS.NO_CONNECTION}`,
        element: <NoConnection />,
    },
    {
        path: `${PATHS.AUTHENTICATION.ROOT}`,
        element: (
            <GuestGuard>
                <Outlet />
            </GuestGuard>
        ),
        children: [
            { path: `${PATHS.AUTHENTICATION.LOGIN}`, element: <Login /> },
            { path: `${PATHS.AUTHENTICATION.PASSWORD_RECOVERY}`, element: <PasswordRecovery /> },
            { path: `${PATHS.AUTHENTICATION.PASSWORD_RESET}`, element: <PasswordReset /> },
            { path: `${PATHS.AUTHENTICATION.REGISTER}`, element: <Register /> },
            { path: `${PATHS.AUTHENTICATION.VERIFY_CODE}`, element: <VerifyCode /> },
            { path: `${PATHS.AUTHENTICATION.MESSAGE_SENT}`, element: <MessageSent /> },
        ],
    },
    {
        path: `${PATHS.DASHBOARD.ROOT}`,
        element: (
            <AuthGuard>
                <DashboardLayout />
            </AuthGuard>
        ),
        children: [
            { path: `${PATHS.DASHBOARD.USER}`, element: <UserDashboard /> },
            { path: `${PATHS.DASHBOARD.EVENT_ANALYTICS}`, element: <EventAnalyticsComponent /> },
            { path: `${PATHS.DASHBOARD.ANALYTICS}`, element: <ArtistMetric /> },
            { path: `${PATHS.DASHBOARD.PRIMARY_TOP_MOVERS}`, element: <PrimaryTopMovers /> },
            { path: `${PATHS.DASHBOARD.SECONDARY_TOP_MOVERS}`, element: <SecondaryTopMovers /> },
            { path: `${PATHS.DASHBOARD.SECONDARY_TOP_SELLING}`, element: <SecondaryTopSelling /> },
            { path: `${PATHS.DASHBOARD.PRIMARY_FAVORITES}`, element: <PrimaryFavorites /> },
            { path: `${PATHS.DASHBOARD.PRIMARY_ANALYTICS}`, element: <PrimaryAnalytics /> },
            { path: `${PATHS.DASHBOARD.SECONDARY_FAVORITES}`, element: <SecondaryFavorites /> },
            { path: `${PATHS.DASHBOARD.SECONDARY_ANALYTICS}`, element: <SecondaryAnalytics /> },
            {
                path: `${PATHS.DASHBOARD.UPCOMING.ROOT}`,
                element: (
                    <AuthGuard>
                        <Outlet />
                    </AuthGuard>
                ),
                children: [
                    { path: `${PATHS.DASHBOARD.UPCOMING.ROOT}`, element: <Overview /> },
                    { path: `${PATHS.DASHBOARD.UPCOMING.WATCHLIST}`, element: <WatchList /> },
                    { path: `${PATHS.DASHBOARD.UPCOMING.BUYING_LIST}`, element: <BuyingList /> },
                ],
            },
            {
                path: `${PATHS.DASHBOARD.METRIC.ROOT}`,
                element: (
                    <AuthGuard>
                        <Outlet />
                    </AuthGuard>
                ),
                children: [
                    { path: `${PATHS.DASHBOARD.METRIC.ROOT}`, element: <ArtistMetric /> },
                    { path: `${PATHS.DASHBOARD.METRIC.ID}`, element: <Metric /> },
                ],
            },
            {
                path: `${PATHS.DASHBOARD.EXTENSION}`,
                element: (
                    <AuthGuard>
                        <Outlet />
                    </AuthGuard>
                ),
                children: [{ path: `${PATHS.DASHBOARD.EXTENSION}`, element: <ExtensionPage /> }],
            },
            {
                path: `${PATHS.DASHBOARD.ALERTS}`,
                element: (
                    <AuthGuard>
                        <Outlet />
                    </AuthGuard>
                ),
                children: [{ path: `${PATHS.DASHBOARD.ALERTS}`, element: <AlertsPage /> }],
            },
            {
                path: `${PATHS.DASHBOARD.ACADEMY}`,
                element: (
                    <AuthGuard>
                        <Outlet />
                    </AuthGuard>
                ),
                children: [{ path: `${PATHS.DASHBOARD.ACADEMY}`, element: <AcademyPage /> }],
            },
            {
                path: `${PATHS.DASHBOARD.SUPPORT}`,
                element: (
                    <AuthGuard>
                        <Outlet />
                    </AuthGuard>
                ),
                children: [{ path: `${PATHS.DASHBOARD.SUPPORT}`, element: <SupportPage /> }],
            },
            {
                path: `${PATHS.DASHBOARD.COMMUNITY}`,
                element: (
                    <AuthGuard>
                        <Outlet />
                    </AuthGuard>
                ),
                children: [{ path: `${PATHS.DASHBOARD.COMMUNITY}`, element: <CommunityPage /> }],
            },
            {
                path: `${PATHS.DASHBOARD.ADMIN.ROOT}`,
                element: (
                    <AuthGuard>
                        <Outlet />
                    </AuthGuard>
                ),
                children: [
                    { path: `${PATHS.DASHBOARD.ADMIN.ROOT}`, element: <Admin /> },
                    { path: `${PATHS.DASHBOARD.ADMIN.SUBSCRIPTION_MANAGEMENT}`, element: <SubscriptionManagement /> },
                    { path: `${PATHS.DASHBOARD.ADMIN.TIER_MANAGEMENT}`, element: <TierManagement /> },
                    { path: `${PATHS.DASHBOARD.ADMIN.VENUE_MATCH}`, element: <VenueMatch /> },
                    { path: `${PATHS.DASHBOARD.ADMIN.EVENT_MATCH}`, element: <EventMatch /> },
                    { path: `${PATHS.DASHBOARD.ADMIN.VENUE_MISMATCH}`, element: <VenueMismatch /> },
                    { path: `${PATHS.DASHBOARD.ADMIN.SEAT_GROUPS}`, element: <SeatGroups /> },
                    { path: `${PATHS.DASHBOARD.ADMIN.VENUE_URLS}`, element: <VenueUrls /> },
                ],
            },
        ],
    },
]

export default routes
